<template>
  <div>alipayddd</div>
</template>

<script>
export default {
mounted() {
  console.log('sdfsdf')
}
}
</script>

<style scoped>

</style>
